import React from 'react'
import Cup from '../images/sprite/cup.gif'
import Transparent from '../images/sprite/transparent.gif'
import { Link } from "react-router-dom";
import { Homeannounce } from './Homeannounce'
import Promot from '../images/sprite/promot.gif'

export default function Footer(props) {





	return (
		<div  >
			{/* <nav  >
				<ul  >
					<li   id="sports">
						<a   href='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>
							<img   className="icon-sports" src={Cup} alt="gif" />Sports
						</a></li>
					<li   id="inPlay">
						<a   href='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`} >
							<img   className="icon-inplay" src={Cup} alt="gif" />In-Play
						</a></li>
					<li   id="home" className={`main-nav ${(props.tabMenu === 3) ? "select" : "null"}`}>
						<a   href='/' className="ui-link">
							<img   className="icon-home" src={Cup} alt="gif" />Home</a></li>
					<li   id="multiMarket">
						<a  href='/d/dash/multimarket' className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-pin" src={Cup} alt="gif" />Multi Markets
						</a></li>
					<li   id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount' className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>< img className="icon-account" src={Cup} alt="gif" />Account</Link>}
						{!props.isLoggedIn && <a onClick={() => { props.checkShowLogin(true); props.changeTab(3) }} className="ui-link">< img className="icon-account" src={Cup} alt="gif" />Account</a>}
					</li>
				</ul>
			</nav> */}
			<nav>
				{/* <Homeannounce/> */}
				<ul class="mini-lobby">

					<li id="home">
						<Link to='/' className={`ui-link ${(props.tabMenu === 3) ? "select" : "null"}`}>
							<img className="icon-home" src={Transparent}/>Home
						</Link>
					</li>

					<li id="deposit">
						<Link
							to={props.isLoggedIn ? '/d/dash/deposit' : '/'}
							onClick={()=> !props.isLoggedIn ? props.checkShowLogin(true) : null}
							  className={`ui-link ${(props.tabMenu === 12) ? "select" : "null"}`}>
							<img className="icon-deposit" src={Transparent}/>Deposit
						</Link>
					</li>
					<li id="Withdraw">
						<Link
							to={props.isLoggedIn ? '/d/dash/withdraw' : '/'}
							onClick={()=> !props.isLoggedIn ? props.checkShowLogin(true) : null}
							className={`ui-link ${(props.tabMenu === 13) ? "select" : "null"}`}>
							<img className="icon-withdraw" src={Transparent}/>Withdraw
						</Link>
					</li>

					<li id="inPlay">
						<Link to='/d/dash/inplay' className={`ui-link ${(props.tabMenu === 2) ? "select" : "null"}`}>
							<img className="icon-inplay" src={Transparent}/>In-Play
						</Link></li>
					{/*<li id="sports">*/}
					{/*	<Link to='/d/dash/sports' className={`ui-link ${(props.tabMenu === 1) ? "select" : "null"}`}>*/}
					{/*		<img className="icon-sports" src={Transparent}/>Sports*/}
					{/*	</Link></li>*/}


					<li id="Support">
						<a href="#"
							  className={`ui-link ${(props.tabMenu === 4) ? "select" : "null"}`}>
							<img className="icon-support" src={Transparent}/>Support
						</a>
					</li>

					<li id="account">
						{props.isLoggedIn && <Link to='/d/dash/myaccount'
												   className={`ui-link ${(props.tabMenu === 5) ? "select" : "null"}`}>
							<img className="icon-account" src={Transparent}/>Account
						</Link>}
						{!props.isLoggedIn && <a onClick={() => {
							props.checkShowLogin(true);
							props.changeTab(3)
						}} className="ui-link"> <img className="icon-account" src={Transparent}/>Account</a>}
					</li>
				</ul>
			</nav>
		</div>
	)
}
