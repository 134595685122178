import React, { useState, useEffect } from 'react'
import {Link, useHistory} from "react-router-dom";
import Transparent from '../images/sprite/transparent.gif'
import Cookies from 'universal-cookie';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai'
import { suid } from 'rand-token';
import axios from 'axios';
import FirsttimeLogin from './Mobilefirstlogin'
import {toast} from "react-toastify";
import {onlyNumberRegex, strongPassRegex, userNameRegex} from "./reg-ex";

toast.configure();

const cookies = new Cookies();

window.flag = 0;

var cc;
var tt;

const signUpUser = async payload => await axios.post('https://liveapi247.live/api1/signup', payload, {
	validateStatus: (status) => status < 500
})

export default function RegisterMobile(props) {
	// const history = useHistory();
	const [isLoading, setIsLoading] = useState(false);
	const [viewpass, setViewpass] = useState(false);
	const [error, setError] = useState('');
	const searchParams = {};

	const defaultData = {
		username: "",
		fullName: "",
		password: "",
		mobile: "",
		refCode: searchParams?.refcode || '',
	}
	const [formData, setFormData] = useState(defaultData);

	const onChange = (key, value) => setFormData({
		...formData,
		[key]: value
	})


	const onSubmit = async ()=> {

		if(!userNameRegex.test(formData.username)){
			toast.error('Username must have 4 to 15 characters without white space and any special characters');
		} else if(!formData.fullName || formData.fullName === '') {
			toast.error('Please Enter Full Name.');
		} else if(!formData.mobile || formData.mobile === '' || !onlyNumberRegex.test(formData.mobile) || formData.mobile.length < 10) {
			toast.error('The Phone Number must have 10 number');
		} else if(!strongPassRegex.test(formData.password)){
			toast.error('Password must have 8 to 15 alphanumeric without white space; cannot be the same as username/nickname; must contain at least 1 capital letter, small letter and numbers; and must not contain any special characters (!,@,#,etc..)');
		} 
		else if(!formData.refCode || formData.refCode === ''){
			toast.error('Please Enter Referral code.');
		}
		  else {
			// success
			setIsLoading(true);
			let token = suid(16);
			await signUpUser({
				...formData,
				token: token,
			}).then(({data, status})=> {
				setIsLoading(false);
				if(status === 200){
					if(data === "Account created successfully!"){
						toast.success('Signup Success', { position: toast.POSITION.TOP_CENTER });
						// setFormData(defaultData);
						cookies.set('id', formData.username, { path: '/' });
						cookies.set('token', token, { path: '/' });
						cookies.set('password', formData.password, { path: '/' });

						setTimeout(()=> {
							window.location.href = '/home';
						},200)
					} else {
						toast.error(data.data.message || data.message || data.data || `Somthing went Wrong!`, { position: toast.POSITION.TOP_CENTER })
					}
				} else {
					toast.error(data.message || data.msg, { position: toast.POSITION.TOP_CENTER })
				}


			})

		}
	}


	return (
		<>
			<body style={{
				color: '#222',
				backgroundColor: 'rgb(255, 0, 169)',
				backgroundImage: 'linear-gradient(180deg, #5fc0a9 0%, #5d6966 81%)',
				minHeight: '100vh'
			}}>

			<header className="login-head">
				<Link to="/" className="close ui-link" style={{ padding: 0 }} onClick={() => props.setShowRegister(false)}></Link>
				<h1>cricbet247</h1>
			</header>
			<dl className="form-login">
				<dd id="loginNameErrorClass">
					<input type="text"
						   value={formData.username}
						   onChange={(e) => onChange('username', e.target.value.toString())}
						   data-role="none"
						   placeholder="Username"/>
				</dd>
				<dd>
					<input type="text"
						   value={formData.fullName}
						   onChange={(e) => onChange('fullName', e.target.value)}
						   data-role="none"
						   placeholder="Full Name"
					/>
				</dd>
				<dd>
					<input type="text"
						   value={formData.mobile}
						   onChange={(e) => onChange('mobile', e.target.value)}
						   data-role="none"
						   placeholder="Phone Number"
					/>
				</dd>

				<dd id="passwordErrorClass">
					<input
						type={viewpass ? "text" : "password"}
						value={formData.password}
						onChange={(e) => onChange('password', e.target.value)}
						placeholder="Password"
					/>

					{viewpass ? (
						<AiFillEye onClick={() => setViewpass(!viewpass)} style={{
							color: 'rgb(0, 0, 0)',
							position: 'absolute',
							top: '2.5vw',
							zIndex: '222222222',
							left: '77vw',
							fontSize: '6vw'
						}}/>
					) : (

						<AiFillEyeInvisible onClick={() => setViewpass(!viewpass)}
											style={{
												color: 'rgb(0, 0, 0)',
												position: 'absolute',
												top: '2.5vw',
												zIndex: '222222222',
												left: '77vw',
												fontSize: '6vw'
											}}/>
					)}
				</dd>

				<dd>
					<input type="text"
						   value={formData.refCode}
						   onChange={(e) => onChange('refCode', e.target.value)}
						   data-role="none"
						   placeholder="Enter Referral Code here..."
					/>
				</dd>


				<dd>
					<button onClick={onSubmit} className="btn-send ui-link" id="loginBtn" style={{width: '100%'}} disabled={isLoading}>{isLoading ? 'Register...' : 'Register'}</button>
				</dd>
				{error && <dd id="errorMsg" className="state">{error}</dd>}

			</dl>


			</body>
		</>
	)

}
