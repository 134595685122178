import React, {useEffect, useState} from 'react';
import SelectBank from "./components/select-bank";
import {fixValue, objIsNotEmpty} from "./utils";
import { FaCopy } from "react-icons/fa6";
import {useCopyToClipboard} from "react-use";
import axios from "axios";
import Cookies from "universal-cookie";
import {toast} from "react-toastify";
import {getRqData} from "../serives/react-query/rq-helper";
import {RQ_KEY} from "../serives/react-query/constant";
const cookies = new Cookies();

toast.configure();

const depositAmount = async ({payload, receipt}) => {
    // await axios.post('https://fair247.vip/deposit', payload)
    const bodyFormData = new FormData();
    bodyFormData.append('jsonData', JSON.stringify(payload));
    bodyFormData.append('image', receipt)
    return await axios.post('https://liveapi247.live/apiv9/deposit', bodyFormData, {
        headers: {
            "Content-Type": "multipart/form-data"
        }
    })
};

const InfoInput  = ({ title, defaultValue, value, copy, ...rest}) => {
    const [showTooltip, setShowTooltip] = useState(false);
    const [state, copyToClipboard] = useCopyToClipboard();

    const onClickCopy = ()=> {
        setShowTooltip(true);
        copyToClipboard(value || defaultValue);
        setTimeout(()=> setShowTooltip(false), 1500);
    }

    return (
        <>
            {title && (
                <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                    <span>{title} </span>
                    {copy && (
                        <div style={{ position: 'relative', display: 'inline-block'}}>
                            <FaCopy size="1.2rem" style={{ cursor: 'pointer'}} onClick={onClickCopy} />
                            {showTooltip && <span style={{
                                "padding": "2px 9px",
                                "backgroundColor": "#000000df",
                                "borderRadius": "4px",
                                "color": "#fff"
                            }}>copied!</span>}

                        </div>
                    )}

                </div>
            )}
            <div style={{display: 'flex'}}>
                <input
                    type="text"
                    className="text-input"
                    style={{
                        "border": "1px solid #00000030",
                        "borderRadius": "6px",
                        "marginBottom": "6px",
                        "padding": "8px",
                        "width": "100%"
                    }}
                    value={value}
                    defaultValue={defaultValue}
                    {...rest}
                />
            </div>
        </>
    )
}


const Deposit = () => {

    const getUserApiData = getRqData(RQ_KEY.STORE_USER_DATA);

    const uplinkBalance = getUserApiData.data ? fixValue(getUserApiData.data.uplinkBalance) : 0;

    const defaultFormData = {
        depositType: 'onlineTransfer',
        paymentMethod: '',
        currPaymentMethod: {},
        receiptName: '',
        receipt: {},
        amount: '',
        txnId: ''
    }

    const [formData, setFormData] = useState(defaultFormData);
    const setFromDataFn = (key, value) => setFormData({
        ...formData,
        [key]: value
    })
    const setMultiFromDataFn = (data) => setFormData({
        ...formData,
        ...data
    })


    const onSubmit = async ()=> {
        let token = cookies.get('token');
        let clientId = cookies.get('id');
        if(clientId){
            clientId = clientId.toUpperCase()
        }

        if(formData.depositType !== 'onlineTransfer'){
            toast.error('Please select Deposit Type', {position:toast.POSITION.TOP_CENTER})
        } else if(!formData.paymentMethod || formData.paymentMethod === '') {
            toast.error('Please select bank.', {position:toast.POSITION.TOP_CENTER})
        } else if(!(uplinkBalance >= 10000)){
            toast.error('Your Upline Balance is not enough please contact your Upline', {position:toast.POSITION.TOP_CENTER});
        } else if(!formData.amount || !(formData.amount >= 100 && formData.amount <= 25000)){
            toast.error(formData.amount > 25000 ? 'Can not Deposit More than 25000': 'Can not Deposit Less than 100', {position:toast.POSITION.TOP_CENTER});
        } else if (!formData.txnId || formData.txnId === ''){
            toast.error('Please Enter Transaction Id.', {position:toast.POSITION.TOP_CENTER});
        } else {
            // success

            await depositAmount({
                payload: {
                    // agentAccountNumber: selectedAccountDetails[0]?.acNo,
                    agentAccountNumber: formData.currPaymentMethod?.agent?.accountNumber,
                    txnId: formData.txnId,
                    amount: fixValue(formData.amount, 'number'),
                    paymentMethod: formData.currPaymentMethod?.id,
                    agentId: formData.currPaymentMethod?.agent?.agentId,
                    clientId,
                    token
                },
                receipt: formData.receipt
            }).then(({data, status})=> {

                if(data && data.status){
                    setFormData(defaultFormData)
                    toast.success(data.msg ?  data.msg : 'Your Amount is Deposit Request Successfully.', { position: toast.POSITION.TOP_CENTER })
                } else {
                    toast.error(data.msg, { position: toast.POSITION.TOP_CENTER })
                }

            })

        }
    }


    return (
        <>
            <div style={{"alignItems": "center", "borderBottom": "2px solid #ff00a9", "padding": "10px 5px"}}>
                <div style={{"color": "#ff00a9", "fontSize": "1.3rem", "fontWeight": "700", "textAlign": "center"}}>Deposit Transaction</div>
            </div>

            <div style={{"marginBottom":"35px","overflowY":"auto","padding":"13px"}}>
                <div style={{
                    "border": "1px solid #00000030",
                    "borderRadius": "6px",
                    "marginBottom": "16px",
                    "padding": "8px"
                }}>

                    <div style={{"display": "inline-block", "marginBottom": "5px"}}>
                        <span>Deposit Type</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <select name="depositType" id="depositType"
                            style={{
                                "border": "1px solid #00000030",
                                "borderRadius": "6px",
                                "marginBottom": "6px",
                                "padding": "8px",
                                "width": "100%"
                            }}
                            onChange={(e) => setFromDataFn('depositType', e.target.value)}
                    >
                        <option value="">Select Deposit Type</option>
                        <option value="onlineTransfer" security="true" selected={true}>Online Transfer</option>
                    </select>
                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Bank</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex'}}>
                        <SelectBank
                            paymentType="d"
                            isSelected={formData?.currPaymentMethod?.id}
                            onChange={i => setMultiFromDataFn({
                                paymentMethod: i?.id,
                                currPaymentMethod: i,
                            })}
                        />
                    </div>
                    {objIsNotEmpty(formData.currPaymentMethod) && (
                        <>
                            <InfoInput
                                value={formData?.currPaymentMethod?.agent?.agentId}
                                disabled={true}
                            />

                            <InfoInput
                                copy={true}
                                title="Bank Account"
                                value={formData?.currPaymentMethod?.agent?.accountNumber}
                            />
                        </>
                    )}

                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Amount</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex', flexWrap: 'wrap'}}>
                        <input
                            name="amount"
                            type="number"
                            className="text-input"
                            id="depositAmt"
                            placeholder="0.00"
                            style={{
                                "border": "1px solid #00000030",
                                "borderRadius": "6px",
                                "marginBottom": "6px",
                                "padding": "8px",
                                "width": "100%"
                            }}
                            value={formData?.amount}
                            onChange={(e) => setFromDataFn('amount', e.target.value)}
                        />
                        <span style={{color: '#ff00a9', fontSize: 'medium'}}>* Min 100 Max 25000</span>
                    </div>

                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Upload Receipt</span>
                    </div>
                    <div>
                        <div style={{
                            "background": "#d7e3f0",
                            "borderRadius": "6px",
                            "marginBottom": "6px",
                            "overflow": "hidden",
                            "position": "relative"
                        }}>
                        <span
                            style={{
                                "display": "block",
                                "padding": "8px",
                                "position": "relative",
                                "textAlign": "center",
                                "width": "100%"
                            }}
                        >Select an Image of payment</span>
                            <input
                                type="file"
                                accept="image/*"
                                name="depositReceipt"
                                onChange={(e) => setMultiFromDataFn({
                                    receiptName: e.target.files[0]?.name,
                                    receipt: e.target.files[0],
                                })}
                                style={{"left": "0", "margin": "0", "opacity": "0", "position": "absolute", "top": "0"}}
                            />
                        </div>
                        <span>{formData.receipt?.name ? formData.receipt.name : ''}</span>
                    </div>


                    <div style={{"display": "inline-block", "marginBottom": "5px", marginTop: '5px'}}>
                        <span>Transaction ID</span>
                        <span style={{color: '#ff00a9'}}>*</span>
                    </div>
                    <div style={{display: 'flex'}}>
                        <input
                            type="text"
                            className="text-input"
                            style={{
                                "border": "1px solid #00000030",
                                "borderRadius": "6px",
                                "marginBottom": "6px",
                                "padding": "8px",
                                "width": "100%"
                            }}
                            value={formData.txnId}
                            onChange={(e) => setFromDataFn('txnId', e.target.value)}
                        />
                    </div>

                    <span style={{color: '#ff00a9', fontSize: 'small'}}> * Enter exact Transaction ID, otherwise Deposit may be cancelled /সঠিক Transaction ID ইনপুট করুন, নাহলে Deposit ক্যানসেল হয়ে যেতে পারে</span>

                    <button type="button" onClick={onSubmit} style={{
                        "backgroundColor": "#ff00a9",
                        "border": "1px solid #ff00a9",
                        "borderRadius": "6px",
                        "color": "#fff",
                        "fontWeight": "700",
                        "marginBottom": "6px",
                        "marginTop": "10px",
                        "padding": "8px",
                        "textAlign": "center",
                        "width": "100%"
                    }}>Submit
                    </button>

                    <div className="deposit-description">
                        <div>
                            <span>Note: Please use Bank Transfer via your local bank account. We do not accept all kinds of deposit by Cheque or Bank Draft (Company OR Personal Cheque) as your deposit method.</span>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span>Once you have successfully submitted your deposit form and once your funds is cleared in our account, just leave it to our team to process your transactions as speedy as possible. If more than 10 minutes, let us know by contacting our Customer Service support. They will assist you 24/7 anytime.</span>
                        </div>
                        <div style={{marginTop: '3px'}}>
                            <span>Please make sure that you fill up accurate bank account details to avoid any inconveniences &amp;amp; using third-party withdrawal is NOT accepted by us.</span>
                        </div>
                    </div>

                </div>
            </div>

        </>
    )
}
export default Deposit;